<template>
  <div class="columns is-centered is-marginless" style="height: 100%">
    <div
      class="column is-6"
      style="height: 100%"
    >
      <div class="column is-narrow">
        <h1 class="is-size-4 has-text-weight-medium">
          Create Appointment
        </h1>
      </div>
      <div class="column is-narrow">
        <form @submit.prevent="create" class="mb-5">
          <b-field
            custom-class=""
            :type="error.startAt ? 'is-danger' : ''"
            :message="error.startAt"
          >
            <b-datetimepicker
              placeholder="Select date"
              icon="calendar-today"
              v-model="appointment.startAt"
              :min-datetime="minDatetime"
              :disabled="!!appointments.length">
            </b-datetimepicker>
          </b-field>
          <b-field
            custom-class=""
            :type="error.patientDesc ? 'is-danger' : ''"
            :message="error.patientDesc"
          >
            <b-input
              placeholder="Description"
              custom-class="custum-input"
              v-model="appointment.patientDesc"
              type="textarea"
              :disabled="!!appointments.length">
            </b-input>
          </b-field>
        </form>
        <div class="columns is-centered">
          <div class="column">
            <b-button
              expanded
              @click="$router.go(-1)"
              class="has-text-weight-bold has-text-primary"
            >
              Go Back
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary"
              expanded
              @click="create"
              class="has-text-weight-bold"
              :disabled="!!appointments.length"
            >
              Create
            </b-button>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { validationAppointmentPatient } from '@/services/validation';
import {
  CreateAppointmentPatient,
  GetAppointment,
  UpdateAppointmentPatient,
  GetAppointmentsUser,
} from '@/api/appointment';

export default {
  metaInfo() {
    return {
      title: this.isEdit ? 'Edit Appointment' : 'Create Appointment',
    };
  },
  components: {},
  data() {
    return {
      appointment: {
        startAt: new Date(),
        patientDesc: '',
      },
      error: {
        startAt: '',
        patientDesc: '',
      },
      minDatetime: new Date(),
      appointments: [],
    };
  },
  computed: {
    ...mapGetters({}),
    isEdit() {
      return this.$route.name === 'EditAppointment';
    },
  },
  methods: {
    ...mapMutations({}),
    async create() {
      if (!validationAppointmentPatient(this.appointment, this.error, 2000)) return '';
      const loadingComponent = this.$buefy.loading.open();
      try {
        if (!this.isEdit) {
          const url = (await CreateAppointmentPatient(this.appointment)).data;
          loadingComponent.close();
          window.location = url;
        } else {
          const res = (await UpdateAppointmentPatient(
            this.$route.params.appointmentId,
            this.appointment,
          )).data;
          loadingComponent.close();
          if (!res.isPay) {
            window.location = res.url;
            return '';
          }
          this.$router.push({ name: 'Appointment' });
        }
        return '';
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return '';
      }
    },
  },
  async mounted() {
    try {
      const res = (await GetAppointmentsUser(true)).data;
      this.appointments = res.appointments;
      if (this.appointments.length) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'You already have create an appointment that has not yet started',
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
      if (this.isEdit) {
        const loadingComponent = this.$buefy.loading.open();
        const appointment = (await GetAppointment(this.$route.params.appointmentId))
          .data.appointment;
        this.appointment = {
          startAt: new Date(appointment.startAt),
          patientDesc: appointment.patientDesc,
        };
        this.searchDoctor = appointment.doctorName;
        this.searchPatient = appointment.patientName;
        loadingComponent.close();
      }
    } catch (error) {
      this.showError(error);
    }
  },
};
</script>
